<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>耗能管理</el-breadcrumb-item>
            <el-breadcrumb-item>省电助手</el-breadcrumb-item>
            <el-breadcrumb-item>编辑</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
         <el-row class="scene-top">
            <el-link class="back_iconfont" style="width: 60px;" type="primary" icon="iconfont iconfanhui1" @click="goBack()">返回</el-link>
            <el-button class="bg-gradient" style="float: right" type="primary"  v-text="$t('msg.save')" @click="handleSave">保存</el-button>
         </el-row>
         <!-- 基本信息 -->
         <div class="det-box">
            <el-divider content-position="left"><span>基本信息</span></el-divider>
            <ul>
               <li>
                  <span><b class="needful">*</b>任务名称：</span>
                  <el-input class="width-193" placeholder="任务名称"  v-model="jobName" maxLength="20" clearable></el-input>
               </li>
               <li>
                  <span><b class="needful">*</b>任务编码：</span>
                  <el-input class="width-193" placeholder="任务编码" maxlength="11"  v-model="jobCode" clearable></el-input>
               </li>
               <li>
                  <span><b class="needful">*</b>所属任务组：</span>
                  <el-select class="width-193" v-model="jobGroup" :placeholder="$t('msg.select')"  clearable>
                     <el-option
                           v-for="item in taskGroup"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>
                  </el-select>
               </li>
               <li v-show="jobGroup !== '2'">
                  <span>任务开始日期：</span>
                  <el-date-picker
                        class="width-193"
                        v-model="startDate"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"

                        clearable
                        placeholder="延时执行日期">
                  </el-date-picker>
               </li>
               <li v-show="jobGroup !== '2'">
                  <span>任务结束日期：</span>
                  <el-date-picker
                        class="width-193"
                        v-model="endDate"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"

                        clearable
                        placeholder="自动关闭日期">
                  </el-date-picker>
               </li>
               <li v-show="jobGroup !== '2'">
                  <span>任务开始时间：</span>
                  <el-time-picker
                        class="width-193"
                        v-model="startTime"
                        value-format="HH:mm:ss"
                        placeholder="延时执行时间">
                  </el-time-picker>
               </li>
            </ul>
            <ul>
               <li v-show="jobGroup !== '2'">
                  <span>任务结束时间：</span>
                  <el-time-picker
                        class="width-193"
                        v-model="endTime"
                        value-format="HH:mm:ss"
                        placeholder="延时执行时间">
                  </el-time-picker>
               </li>
            </ul>
            <div class="remarks-span">
               <span>任务描述：</span>
               <el-input type="textarea" v-model="remark" placeholder="任务描述"></el-input>
            </div>
         </div>
         <!-- 触发条件 -->
         <el-row class="trigger-condition">
            <el-divider content-position="left"><span>触发条件</span></el-divider>
            <el-row class="condition-cont">
               <el-tabs v-model="conditionType" type="card" @tab-click="changeTab">
                  <el-tab-pane label="定时" name="TIME">
                     <el-popover v-model="cronPopover">
                        <cron @change="changeCron" @close="cronPopover = false" i18n="cn"></cron>
                        <el-input slot="reference" @click="cronPopover = true" v-model="cronExpression" placeholder="请输入定时条件"></el-input>
                     </el-popover>
                  </el-tab-pane>
                  <el-tab-pane label="房态" name="ROOM_STATE">
                     <el-row class="room-btn">
                        <button @click="changeRoomState('CHECKIN')" :class="conditionParam === 'CHECKIN' ? 'active' : ''">入住</button>
                        <button @click="changeRoomState('CHECKOUT')" :class="conditionParam === 'CHECKOUT' ? 'active' : ''">退房</button>
                        <button @click="changeRoomState('UNINHABITED')" :class="conditionParam === 'UNINHABITED' ? 'active' : ''">无人</button>
                     </el-row>
                  </el-tab-pane>
                  <!--<el-tab-pane label="场景" name="SCENE">
                     <el-row class="m-bottom-10" style="font-size: 14px">
                        <span><b class="needful">*</b>执行时间：</span>
                        <el-input class="width-100" placeholder="执行时间"  v-model="delayedTime" clearable></el-input>&nbsp;秒
                     </el-row>
                     <el-row class="room-btn">
                        <button v-for="(item, index) of sceneList" :key="index" @click="changeScene(item.id)" :class="sceneId === item.id ? 'active' : ''">
                           {{item.sceneName}}
                        </button>
                     </el-row>
                  </el-tab-pane>-->
                  <el-tab-pane label="开门" name="DOOR">
                     <el-row class="m-bottom-10" style="font-size: 14px">
                        <span><b class="needful">*</b>执行时间：</span>
                        <el-input class="width-100" placeholder="执行时间"  v-model="delayedTime" clearable></el-input>&nbsp;分钟
                     </el-row>
                  </el-tab-pane>
                  <el-tab-pane label="插卡" name="INTO_THE_ROOM_CARD"></el-tab-pane>
               </el-tabs>
            </el-row>
         </el-row>
         <!-- 生效房间 -->
         <el-row class="effective-room">
            <el-divider content-position="left"><span>生效房间</span></el-divider>
            <el-row class="room-cont">
               <el-row>
                  <el-col :span="8">
                     <el-radio-group v-model="roomScope" @change="handleChange">
                        <el-radio label="ROOMTYPE">指定房型</el-radio>
                        <el-radio label="ROOM">指定房间</el-radio>
                        <el-radio label="ALL">所有房间</el-radio>
                     </el-radio-group>
                  </el-col>
                  <el-col :span="16" class="infrared-sensor" v-if="conditionParam === 'UNINHABITED'">
                     <b class="needful">*</b>
                     红外感应器静默
                     <el-input-number  v-model="delayedTime" :min="1" :max="1440" label="描述文字"></el-input-number>
                     分钟，为无人状态。（时间范围为1~1440分钟）
                  </el-col>
               </el-row>
               <!-- 指定房型 -->
               <el-row class="room-type" v-show="roomScope === 'ROOMTYPE'">
                  <el-row class="room-type-images">
                     <div :class="roomTypeIds.includes(item.roomTypeId) ? 'active' : ''" v-for="(item, index) in roomTypes" :key="index" @click="selectedRoomType(item)">
                        <img src="@/assets/1111.jpg" alt="">
                        <span>{{item.roomTypeName}}</span>
                     </div>
                  </el-row>
               </el-row>
               <!-- 指定房间 -->
               <el-row class="room" v-show="roomScope === 'ROOM'">
                  <el-row class="room-top">
                     <b class="needful">*</b>楼栋/单元：
                     <el-cascader
                           v-model="buildIds"
                           :options="buildList"
                           @change="changeBuild"
                           clearable
                           class="width-280"></el-cascader>
                  </el-row>
                  <el-row class="room-btm">
                     <el-row v-for="(item, index) in roomData" :key="index">
                        <el-col class="room-btm-head">
                           <!--<el-checkbox :indeterminate="isIndeterminate" @change="val => handleCheckAllChange(item.floor, val)">{{item.floor}}层</el-checkbox>-->
                           {{item.floor}}层
                        </el-col>
                        <el-col>
                           <el-checkbox-group v-model="checkedRooms" @change="handleCheckedRoomsChange">
                              <el-checkbox :label="item.roomId" v-for="(item, index) in item.rooms" :key="index">{{item.roomNo}}</el-checkbox>
                           </el-checkbox-group>
                        </el-col>
                     </el-row>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
         <!-- 生效设备 -->
         <div class="det-box">
            <el-divider content-position="left"><span>生效设备</span></el-divider>
            <!-- 产品 -->
            <div class="execute">
               <div class="execute-room">
                  <div class="top-text"><span>产品列表</span></div>
                  <el-table
                        :data="productData"
                        border
                        height="360"
                        highlight-current-row
                        @selection-change="getSelectProduct"
                        @current-change="handleTableSelect"
                        :stripe="true"
                        >
                     <el-table-column type="selection" width="55"></el-table-column>
                     <el-table-column prop="id" label="产品编码"></el-table-column>
                     <el-table-column prop="standard" label="产品规格"></el-table-column>
                  </el-table>
               </div>
               <div>
                  <el-row class="execute-tag">
                     <div class="top-text"><span>已选产品</span></div>
                     <el-table :data="sProductData"
                              border
                              ref="productTable"
                              height="420"
                              style="overflow-y: auto"
                              @expand-change="handleExpand"
                              :stripe="true">
                        <el-table-column type="expand">
                           <template slot-scope="props" v-if="props.row.isChildren">
                              <el-table
                                    :data="props.row.params"
                                    style="width: 100%;"
                                    >
                                 <el-table-column label="产品参数" min-width="140">
                                    <template slot-scope="scope">
                                       <span>{{scope.row.operationType | filterType}}</span>
                                    </template>
                                 </el-table-column>
                                 <el-table-column v-if="props.row.showPartake" label="是否参与任务" min-width="100">
                                    <template slot-scope="scope">
                                       <el-switch  style="margin: 0 5px;"
                                                   v-model="scope.row.isPartake"
                                                   active-color="#67c23a"
                                                   inactive-color="#dcdfe6"
                                                   active-value="YES"
                                                   inactive-value="NO">
                                       </el-switch>
                                    </template>
                                 </el-table-column>
                                 <!-- 温控面板参数一览 -->
                                 <el-table-column label="参数值" v-if="props.row.type === 'temp'" min-width="100">
                                    <template slot-scope="scope">
                                       <!-- 开关 -->
                                       <el-switch
                                             v-if="scope.row.operationType === 'switchState'"
                                             @change="() => changeSubState(props.row)"
                                             style="margin: 0px 5px;"
                                             v-model="scope.row.value"
                                             active-color="#67c23a"
                                             inactive-color="#dcdfe6"
                                             active-value="TURNON"
                                             inactive-value="TURNOFF">
                                       </el-switch>
                                       <!-- 模式 -->
                                       <el-radio-group
                                             v-else-if="scope.row.operationType === 'tempPanelMode'"
                                             v-model="scope.row.value">
                                          <el-radio label="COLD">制冷</el-radio>
                                          <el-radio label="HEAT">制热</el-radio>
                                       </el-radio-group>
                                       <!-- 风速 -->
                                       <el-select
                                             v-else-if="scope.row.operationType === 'windSpeed'"
                                             v-model="scope.row.value"

                                             class="width-100">
                                          <el-option
                                                v-for="item in [
                                                   {label: '自动', value: 'AUTO'},
                                                   {label: '低', value: 'LOW'},
                                                   {label: '中', value: 'MEDIUM'},
                                                   {label: '高', value: 'HIGH'}
                                                ]"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                          </el-option>
                                       </el-select>
                                       <!-- 温度 -->
                                       <el-slider
                                             v-else

                                             :min="15"
                                             :max="36"
                                             :marks="{ 15: '15°C', 25: { style: { color: '#1989FA' } } }"
                                             v-model="scope.row.value">
                                       </el-slider>
                                    </template>
                                 </el-table-column>
                                 <!-- 开关/场景面板参数一览 -->
                                 <el-table-column label="参数值" v-else-if="props.row.type === 'switch'" min-width="100">
                                    <template slot-scope="scope">
                                       <el-switch
                                             style="margin: 0px 5px;"
                                             @change="() => changeSubState(props.row)"
                                             v-model="scope.row.value"
                                             active-color="#67c23a"
                                             inactive-color="#dcdfe6"
                                             active-value="TURNON"
                                             inactive-value="TURNOFF">
                                       </el-switch>
                                    </template>
                                 </el-table-column>
                              </el-table>
                           </template>
                        </el-table-column>
                        <el-table-column prop="id" label="产品编码" min-width="140"></el-table-column>
                        <el-table-column prop="standard" label="产品规格" min-width="100"></el-table-column>
                        <el-table-column label="状态" min-width="100">
                           <template slot-scope="scope">
                              <el-switch
                                    :class="[scope.row.state ? '' : 'switch-state']"
                                    style="margin: 0px 5px;"
                                    v-model="scope.row.state"
                                    @change="val => changeState(scope.row, val)"
                                    active-color="#67c23a"
                                    inactive-color="#dcdfe6"
                                    active-value="TURNON"
                                    :inactive-value="scope.row.isState ? '' : 'TURNOFF'">
                              </el-switch>
                           </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.operating')" width="100"  fixed="right">
                           <template slot-scope="scope">
                              <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                 <el-link type="danger" v-text="$t('msg.remove')" @click="delProduct(scope.row)">删除</el-link>
                              </el-button>
                           </template>
                        </el-table-column>
                     </el-table>
                     <p class="arrow"><i class="el-icon-d-arrow-right" @click="addProduct"></i></p>
                  </el-row>
               </div>
            </div>
            <!-- 设备 -->
            <div class="execute">
               <div class="execute-room">
                  <div class="top-text"><span>设备列表</span></div>
                  <el-table :data="devicesData"
                           border
                           height="360"
                           @selection-change="getSelectDevices"
                           :stripe="true"
                           >
                     <el-table-column type="selection" width="55"></el-table-column>
                     <el-table-column prop="deviceName" label="设备编码" min-width="140"></el-table-column>
                     <el-table-column prop="versionsName" label="设备别名"></el-table-column>
                  </el-table>
               </div>
               <div>
                  <el-row class="execute-tag">
                     <div class="top-text"><span>已选设备</span></div>
                     <el-table
                           :data="sDevicesData"
                           border
                           ref="deviceTable"
                           height="420"
                           style="overflow-y: auto"
                           @expand-change="handleExpand"
                           :stripe="true"
                           >
                        <el-table-column type="expand">
                           <template slot-scope="props" v-if="props.row.isChildren">
                              <el-table
                                    :data="props.row.params"
                                    style="width: 100%;"
                                    >
                                 <el-table-column label="设备参数" min-width="140">
                                    <template slot-scope="scope">
                                       <span>{{scope.row.operationType | filterType}}</span>
                                    </template>
                                 </el-table-column>
                                 <el-table-column v-if="props.row.showPartake" label="是否参与任务" min-width="100">
                                    <template slot-scope="scope">
                                       <el-switch  style="margin: 0px 5px;"
                                                   v-model="scope.row.isPartake"
                                                   active-color="#67c23a"
                                                   inactive-color="#dcdfe6"
                                                   active-value="YES"
                                                   inactive-value="NO">
                                       </el-switch>
                                    </template>
                                 </el-table-column>
                                 <!-- 温控面板参数一览 -->
                                 <el-table-column label="参数值" v-if="props.row.type === 'temp'" min-width="100">
                                    <template slot-scope="scope">
                                       <!-- 开关 -->
                                       <el-switch
                                             v-if="scope.row.operationType === 'switchState'"
                                             @change="() => changeSubState(props.row)"
                                             style="margin: 0px 5px;"
                                             v-model="scope.row.value"
                                             active-color="#67c23a"
                                             inactive-color="#dcdfe6"
                                             active-value="TURNON"
                                             inactive-value="TURNOFF">
                                       </el-switch>
                                       <!-- 模式 -->
                                       <el-radio-group v-else-if="scope.row.operationType === 'tempPanelMode'"
                                                      v-model="scope.row.value">
                                          <el-radio label="COLD">制冷</el-radio>
                                          <el-radio label="HEAT">制热</el-radio>
                                       </el-radio-group>
                                       <!-- 风速 -->
                                       <el-select
                                             v-else-if="scope.row.operationType === 'windSpeed'"
                                             v-model="scope.row.value"

                                             class="width-100">
                                          <el-option
                                                v-for="item in [
                                                   {label: '自动', value: 'AUTO'},
                                                   {label: '低', value: 'LOW'},
                                                   {label: '中', value: 'MEDIUM'},
                                                   {label: '高', value: 'HIGH'}
                                                ]"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                          </el-option>
                                       </el-select>
                                       <!-- 温度 -->
                                       <el-slider
                                             v-else

                                             :min="15"
                                             :max="36"
                                             :marks="{ 15: '15°C', 25: { style: { color: '#1989FA' } } }"
                                             v-model="scope.row.value">
                                       </el-slider>
                                    </template>
                                 </el-table-column>
                                 <!-- 开关/场景面板参数一览 -->
                                 <el-table-column label="参数值" v-else-if="props.row.type === 'switch'" min-width="100">
                                    <template slot-scope="scope">
                                       <el-switch
                                             style="margin: 0px 5px;"
                                             @change="() => changeSubState(props.row)"
                                             v-model="scope.row.value"
                                             active-color="#67c23a"
                                             inactive-color="#dcdfe6"
                                             active-value="TURNON"
                                             inactive-value="TURNOFF">
                                       </el-switch>
                                    </template>
                                 </el-table-column>
                              </el-table>
                           </template>
                        </el-table-column>
                        <el-table-column prop="deviceName" label="设备编码" min-width="140"></el-table-column>
                        <el-table-column prop="versionsName" label="设备规格" min-width="100"></el-table-column>
                        <el-table-column prop="state" label="状态" min-width="100">
                           <template slot-scope="scope">
                              <el-switch
                                    :class="[scope.row.state ? '' : 'switch-state']"
                                    style="margin: 0px 5px;"
                                    v-model="scope.row.state"
                                    @change="val => changeState(scope.row, val)"
                                    active-color="#67c23a"
                                    inactive-color="#dcdfe6"
                                    active-value="TURNON"
                                    :inactive-value="scope.row.isState ? '' : 'TURNOFF'">
                              </el-switch>
                           </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.operating')" width="100"  fixed="right">
                           <template slot-scope="scope">
                              <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                 <el-link type="danger" v-text="$t('msg.remove')" @click="delDevices(scope.row)">删除</el-link>
                              </el-button>
                           </template>
                        </el-table-column>
                     </el-table>
                     <p class="arrow" @click="addDevices"><i class="el-icon-d-arrow-right"></i></p>
                  </el-row>
               </div>
            </div>
         </div>
      </el-row>
   </div>
</template>

<script>
import { cron } from 'vue-cron'
   import { urlObj } from '@/api/interface'
   import { electricity } from "@/api/interface/aiot";
export default {
      name: 'createTasks',
      data () {
         return {
            action: '',            // 当前操作行为
            taskGroup: [           // 任务组
               {id: '1', name: '定时任务'},
               {id: '2', name: '房态任务'},
            ],
            /* 基本信息 */
            id: '',                // 任务id
            jobId: '',             // 任务关联表ID
            hotelId: '',           // 酒店ID
            jobName: '',           // 任务名称
            jobCode: '',           // 任务编码
            jobGroup: '',          // 任务分组名称
            startDate: '',         // 延迟执行日期
            endDate: '',           // 自动关闭日期
            startTime: '',         // 延迟执行时间
            endTime: '',           // 自动关闭时间
            remark: '',            // 任务描述
            /* 生效房间 */
            roomTypes: [],         // 房型列表
            buildList: [],         // 楼层列表
            buildIds: [],          // 当前楼层ID
            roomData: [],          // 房间列表数据
            newArr: [],
            checkedRooms: [],      // 选中房间
            rooms: [],             // 房间范围/ID
            roomTypeIds: [],       // 房型ID数组
            roomId: [],            // 房间ID
            roomScope: 'ROOMTYPE', // 房间范围
            isIndeterminate: false,// 选中样式
            delayedTime: 1,        // 红外感应器时间
            /* 触发条件 */
            sceneList: [],         // 场景列表
            scenesId: [],          // 场景Ids
            sceneId: '',           // 场景Id
            cronPopover: false,    // 弹窗是否显示
            cronExpression: '',    // 表达式内容
            conditionType: 'TIME', // 当前选中tab
            conditionParam: 'CHECKIN', // 房态类型
            /* 生效设备 */
            productData: [],       // 产品列表
            selectProducts: [],    // 勾选产品列表
            sProductData: [],      // 已选中产品列表
            devicesData: [],       // 设备列表
            selectDevices: [],     // 勾选设备列表
            sDevicesData: [],      // 已选中设备列表
            devices: [],           // 提交产品/设备字段
            productId: '',         // 产品编码
            /* 提示文本 */
            select_success: '请先选中生效的房型或房间！',
            prevent_add: '当前设备的产品分类已选中！',
            prevent_add_dev: '当前设备已选中',
            jobName_no_null: '任务名称不能为空！',
            jobCode_no_null: '任务编码不能为空！',
            jobGroup_no_null: '任务分组不能为空！',
            startTime_no_null: '延迟时间不能为空！',
            endTime_no_null: '关闭时间不能为空！',
            create_success: '创建定时任务成功！',
            select_room_or_roomtype: '请先选中生效的房型或房间！',
            cronExpression_no_null: '触发定时条件不能为空！'
         }
      },
      components: {
         cron
      },
      mounted () {
         this.hotelId = this.$route.query.hotelId
         this.action = this.$route.query.action
         this.getRoomTypes()
         this.id = this.$route.query.id
         // 当前操作为编辑时调用
         this.id && this.getElectricityDetail()
      },
      methods: {
         // 获取(回显)任务详情
         getElectricityDetail(){
            const url = electricity.electricityDetail + `/${this.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success){
                  let data = res.records
                  this.jobId = data.job.id
                  this.jobName = data.job.jobName
                  this.jobCode = data.job.jobCode
                  this.jobGroup = data.job.jobGroup
                  this.startDate = data.job.startDate
                  this.endDate = data.job.endDate
                  this.startTime = data.job.startTime
                  this.endTime = data.job.endTime
                  this.remark = data.job.remark
                  this.conditionType = data.conditionType
                  if (data.hasOwnProperty('delayedTime')) {
                     if (data.conditionType === 'DOOR'){
                        this.delayedTime = data.delayedTime
                     }else {
                        this.delayedTime = data.delayedTime / 60
                     }
                  }
                  if (data.hasOwnProperty('conditionParam')) this.conditionParam = data.conditionParam
                  this.cronExpression = data.job.cronExpression
                  this.rooms = data.rooms.map(item => {
                     return {
                        id: item.id,
                        roomOrTypeId: item.roomOrTypeId,
                        roomScope: item.roomScope,
                        buildId: item.buildId,
                        buildUnitId: item.buildUnitId
                     }
                  })
                  // 获取触发房型ID
                  for (let i = 0; i < this.rooms.length; i ++ ){
                     if (this.rooms[i].roomScope === 'ROOM'){
                        this.roomScope = 'ROOM'
                        this.buildIds[0] = this.rooms[i].buildId
                        this.buildIds[1] = this.rooms[i].buildUnitId
                        // 获取楼栋层级
                        this.getBuildings()
                        // 获取房间号
                        this.getFloors(this.buildIds[1])
                        break
                     }
                  }
                  this.rooms.forEach(item => {
                     if (item.roomScope === 'ROOMTYPE'){
                        this.roomScope = 'ROOMTYPE'
                        this.roomTypeIds.push(item.roomOrTypeId)
                     }
                     if (item.roomScope === 'ROOM'){
                        this.checkedRooms.push(item.roomOrTypeId)
                     }
                     if (item.roomScope === 'ALL'){
                        this.roomScope = 'ALL'
                        this.rooms.push({roomScope: this.roomScope})
                     }
                  })
                  this.checkedRooms.length > 0 && this.handleCheckedRoomsChange(this.checkedRooms)
                  this.roomScope === 'ALL' && this.getProductList()
                  this.roomScope === 'ROOMTYPE' && this.getProductList('edit')
                  // 过滤出产品列表 并映射相应字段
                  this.sProductData = data.devicesDTO.filter(item => {
                     return item.deviceScope === 'PRODUCT'
                  }).map(item => {
                     // 如果产品类型是开关类
                     if (item.productOrDeviceId.substring(0, 4) === 'HOSW' ||
                        item.productOrDeviceId.substring(0, 4) === 'HOSC'){
                        /*
                         * isState字段是用来记录后台返回的state值是否为空
                         * 开关类比较特殊 总状态会有三种状态 必须要从extra.state取值
                         * 当extra.state为空时，为第三种状态即半勾选状态 状态颜色为橙色
                         * */
                        let isState
                        let extra = JSON.parse(item.extra)
                        if (extra.state === '') isState = true
                        return {
                           id: item.productOrDeviceId,
                           editId: item.id,
                           elecId: item.elecId,
                           standard: item.standard,
                           isState,
                           state: extra.state,
                           isReturn: true, // isReturn字段是用来记录该数据是否由后台返回
                                           // 是则在展开时 发起ajax请求获取子参数数据 否则不发
                        }
                     }
                     // 如果产品类型是温控、智能锁、感应器等其他设备
                     else {
                        return {
                           id: item.productOrDeviceId,
                           editId: item.id,
                           elecId: item.elecId,
                           standard: item.standard,
                           state: item.action, // 其他产品的勾选状态直接从action里取值即可
                           isReturn: true,
                        }
                     }
                  })
                  // 为产品添加子参数列表
                  this.packageJudgeType(this.sProductData, 'id')
                  // 自动展开所有产品的树形子列表
                  this.sProductData.forEach(item => this.$refs.productTable.toggleRowExpansion(item))
                  // 过滤出设备列表 并映射相应字段
                  this.sDevicesData = data.devicesDTO.filter(item => {
                     return item.deviceScope === 'DEVICE'
                  }).map(item => {
                     if (item.productOrDeviceId.substring(0, 4) === 'HOSW' ||
                        item.productOrDeviceId.substring(0, 4) === 'HOSC'){
                        let isState
                        let extra = JSON.parse(item.extra)
                        if (extra.state === '') isState = true
                        return {
                           id: item.productOrDeviceId,
                           editId: item.id,
                           elecId: item.elecId,
                           productId: item.productId,
                           deviceName: item.deviceName,
                           versionsName: item.standard,
                           isState,
                           state: extra.state,
                           isReturn: true
                        }
                     }else {
                        return {
                           id: item.productOrDeviceId,
                           editId: item.id,
                           elecId: item.elecId,
                           productId: item.productId,
                           deviceName: item.deviceName,
                           versionsName: item.standard,
                           state: item.action,
                           isReturn: true
                        }
                     }
                  })
                  // 为设备添加子参数列表
                  this.packageJudgeType(this.sDevicesData, 'productId')
                  // 自动展开所有设备的树形子列表
                  this.sDevicesData.forEach(item => this.$refs.deviceTable.toggleRowExpansion(item))
               }
            })
         },
         // 获取表达式内容
         changeCron(val){
            this.cronExpression = val
         },
         // 改变触发条件
         changeTab(){
            // 重置执行时间
            this.delayedTime = 1
            if (this.conditionType === 'SCENE'){
               const url = urlObj.getScenePage + `?limit=${20}&page=${1}`
               const param = { hotelId: this.hotelId }
               this.$axios.post(url, param, 'json').then(res => {
                  if (res.success){
                     let sceneList = res.records
                     this.sceneList = sceneList.map(item => ({ id: item.id, sceneName: item.sceneName }))
                  }
               })
            }
         },
         // 改变房态类型
         changeRoomState(val){
            this.conditionParam = val
         },
         // 改变场景模式
         changeScene(val){
            // 多选
            /*if (this.scenesId.includes(val)){
               this.scenesId = this.scenesId.filter(item => {
                  return item !== val
               })
               return
            }
            this.scenesId.push(val)*/
            this.sceneId = val
         },
         // 获取酒店房型列表
         getRoomTypes() {
            const url = urlObj.getHotelRoomTypeList
            const param = { hotelId: this.hotelId }
            this.$axios.post(url, param).then(res => res.success && (this.roomTypes = res.records))
         },
         // 选中当前房型
         selectedRoomType(row){
            // 如果已选中 再点击则取消选中
            if (this.roomTypeIds.includes(row.roomTypeId)){
               this.roomTypeIds = this.roomTypeIds.filter(item => item !== row.roomTypeId)
               this.rooms = this.rooms.filter(item => item.roomOrTypeId !== row.roomTypeId)
               this.getProductList()
               return
            }
            this.roomTypeIds.push(row.roomTypeId)
            this.rooms.push({roomScope: this.roomScope, roomOrTypeId: row.roomTypeId})
            this.getProductList()
         },
         // 切换生效房间类型
         handleChange(val){
            // 清空选中房型
            this.rooms = []
            this.roomId = []
            this.roomTypeIds = []
            this.productData = []
            if (val === 'ROOM' && this.buildList.length === 0) this.getBuildings()
            if (val === 'ALL') {
               this.rooms.push({roomScope: this.roomScope})
               this.getProductList()
            }
         },
         // 获取酒店楼栋列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item1 => {
                     let children = []
                     item1.buildUnits && item1.buildUnits.length > 0 && (children = item1.buildUnits.map(item2 => ({value: item2.id, label: item2.unitName})))
                     return {value: item1.id, label: item1.buildName, children}
                  })
               }
            })
         },
         // 获取楼栋单元层级
         changeBuild(val){
            this.getFloors(val[1])
         },
         // 获取当前楼层列表
         getFloors(unitId){
            const url = urlObj.getUnit + `/${unitId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return this.roomData = []
                  let floors = []
                  for (let i = beginFloor; i <= endFloor; i++) floors.push(i)
                  this.floors = floors
                  this.getRoomInfoList(unitId, (roomList) => {
                     if (this.action === 'edit' && this.roomScope === 'ROOM'){
                        roomList.forEach(item1 => {
                           this.rooms.forEach(item2 => item1.roomNo === item2.roomOrTypeId && this.checkedRooms.push(item1.roomId))
                        })
                     }
                  })
               }
            })
         },
         // 获取当前楼栋下房间列表
         getRoomInfoList(buildUnitId, callback){
            const url = electricity.roomInfoList
            const param = { hotelId: this.hotelId, buildUnitId }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  let roomList = res.records
                  let roomData = []
                  let roomObj = {rooms: []}
                  this.floors.forEach(item1 => {
                     roomObj = {rooms: []}
                     roomList.forEach(item2 => {
                        if (item1 === item2.floor){
                           roomObj.floor = item1
                           roomObj.rooms.push({roomNo: item2.roomNo, roomId: item2.id})
                        }
                     })
                     roomObj.rooms.length !== 0 && roomData.push(roomObj)
                  })
                  this.roomData = roomData
                  callback && callback(roomList)
               }
            })
         },
         // 楼层房间全选
         /*handleCheckAllChange(floor, val) {
            this.isIndeterminate = false;
            if (val){
               let arr = this.roomData.filter(item => {
                  return item.floor === floor
               })[0].rooms
               this.newArr.push(arr)
            }else {
               let arr = this.roomData.filter(item => {
                  return item.floor === floor
               })[0].rooms
               this.newArr = this.newArr.filter(item => {
                  return item !== arr
               })
            }
            this.checkedRooms = this.newArr.flat()
         },*/
         // 楼层房间单选
         handleCheckedRoomsChange(roomId) {
            this.roomId = roomId
            if (this.roomId.length > 0){
               this.getProductList()
            }else {
               this.productData = []
            }
            //this.roomId.length > 0 &&
            //this.rooms.push({roomOrTypeId: value})
            /*let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;*/
         },
         // 获取产品列表
         getProductList(from = ''){
            const url = electricity.roomTypeProducts
            let params = {
               hotelId: this.hotelId,
               roomTypeIds: this.roomTypeIds.toString(),
               roomId: this.roomId[0],
               roomScope: this.roomScope === 'ROOM' ? 'ALL' : this.roomScope
            }
            if (this.roomId.length > 1) {
               delete params.roomId
               params.roomIds = this.roomId.toString()
            }
            this.$axios.post(url, params).then(res => {
               if (res.success) {
                  if (!res.hasOwnProperty('records')) return this.productData = []
                  let productData = res.records
                  // 从产品列表中过滤掉网关类型 并映射字段
                  this.productData = productData.map(item => {
                     return {id: item.productId, standard: item.versionsName}
                  })
                  // from等于edit时，为编辑操作
                  // 将后台返回的已选中产品 从产品列表中剔除
                  from === 'edit' && this.packageDelSelected(this.productData, this.sProductData)
               }
            }).catch(error => {
               this.roomTypeIds.pop()
               this.rooms.pop()
            })
         },
         // 单选当前产品
         handleTableSelect(row){
            if (row) this.productId = row.id; this.getDevicesList()
         },
         // 多选当前产品
         getSelectProduct(val){
            this.selectProducts = val
         },
         // 改变产品/设备总状态
         changeState(row, val){
            // 如果类型是开关
            if (row.type === 'switch'){
               if (row.hasOwnProperty('isState')) delete row.isState
               if (row.hasOwnProperty('params')){
                  row.params.forEach(item => {
                     if (val === 'TURNON') return item.value = 'TURNON'
                     item.value = 'TURNOFF'
                  })
               }
            }
            // 如果类型是温控
            if (row.type === 'temp'){
               if (val === 'TURNON') return row.params[0].value = 'TURNON'
               row.params[0].value = 'TURNOFF'
            }
         },
         // 获取产品/设备子参数列表
         handleExpand(row, expandedRows){
            if (expandedRows.length === 0) return
            /*
            * 数据中有isReturn字段说明该数据来自后台
            * 允许请求参数列表
            * */
            if (row.hasOwnProperty('isReturn')){
               const url = electricity.deviceParams
               const param = { productOrDeviceId: row.id }
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     // 使用双循环对原始的参数数据进行查找及修改
                     let data = res.records
                     // 如果类型是开关类
                     if (row.type === 'switch'){
                        row.params.forEach(item1 => {
                           data.forEach(item2 => {
                              if (item1.operationType.slice(-1) == item2.indexs){
                                 item1.value = item2.action
                                 item1.isPartake = 'YES'
                                 item1.id = item2.id
                              }
                           })
                        })
                     }
                     // 如果类型是温控面板
                     if (row.type === 'temp'){
                        let extra = JSON.parse(data[0].extra)
                        row.params[0].value = extra.switchState
                        row.params[1].value = extra.tempPanelMode
                        row.params[2].value = extra.windSpeed
                        row.params[3].value = extra.temp
                     }
                  }
               })
            }
         },
         // 改变产品/设备子参数状态
         changeSubState(row){
            // 如果类型是开关
            if (row.type === 'switch'){
               if (row.params.every(item => item.value === 'TURNON')){
                  row.state = 'TURNON'
                  return
               }
               if (row.params.every(item => item.value === 'TURNOFF')){
                  row.state = 'TURNOFF'
                  return
               }
               row.state = ''
            }
            // 如果类型是温控
            if (row.type === 'temp'){
               if (row.params[0].value === 'TURNON'){
                  row.state = 'TURNON'
               }else {
                  row.state = 'TURNOFF'
               }
            }
         },
         // 新增选中产品
         addProduct(){
            // 将选中的产品添加到已选产品列表数组中
            this.selectProducts.forEach(item => this.sProductData.push(item))
            // 从列表中删除产品
            this.packageDelSelected(this.productData, this.sProductData)
            // 判断产品类型
            this.packageJudgeType(this.sProductData, 'id')
         },
         // 封装删除产品/设备列表中已选中的元素
         packageDelSelected(data, selectData){
            for (let i = data.length - 1;i >= 0;i --){
               for (let j = 0;j <= selectData.length - 1;j ++){
                  data[i].id === selectData[j].id && data.splice(i, 1)
               }
            }
         },
         // 封装判断产品/设备类型 并添加子参数列表
         packageJudgeType(selectData, id){
            selectData.forEach(item => {
               // 如果产品/设备类型为开关面板或场景面板
               if (item[id].substring(0, 4) === 'HOSW' ||
                  item[id].substring(0, 4) === 'HOSC'){
                  item.isChildren = true
                  item.type = 'switch'
                  item.showPartake = true
                  // 如果类型为去电开关、调光面板、机械手开关或一位开关
                  if (Number(item[id].substring(6, 8)) > 6 ||
                     Number(item[id].substring(6, 8)) === 1 ){
                     if (!item.hasOwnProperty('params')){
                        item.params = [
                           { operationType: 'index0',isPartake: 'NO',value: 'TURNOFF' },
                        ]
                     }
                  }
                  // 类型为二位开关
                  if (Number(item[id].substring(6, 8)) === 2){
                     if (!item.hasOwnProperty('params')){
                        item.params = [
                           { operationType: 'index0',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index1',isPartake: 'NO',value: 'TURNOFF' },
                        ]
                     }
                  }
                  // 类型为三位开关
                  if (Number(item[id].substring(6, 8)) === 3){
                     if (!item.hasOwnProperty('params')){
                        item.params = [
                           { operationType: 'index0',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index1',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index2',isPartake: 'NO',value: 'TURNOFF' },
                        ]
                     }
                  }
                  // 类型为四位开关
                  if (Number(item[id].substring(6, 8)) === 4){
                     if (!item.hasOwnProperty('params')){
                        item.params = [
                           { operationType: 'index0',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index1',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index2',isPartake: 'NO',value: 'TURNOFF' },
                           { operationType: 'index3',isPartake: 'NO',value: 'TURNOFF' },
                        ]
                     }
                  }
               }
               // 如果产品/设备类型为温控面板
               if (item[id].substring(0, 4) === 'HOTC'){
                  item.isChildren = true
                  item.type = 'temp'
                  item.showPartake = false
                  item.params = [
                     { operationType: 'switchState', value: 'TURNOFF' },
                     { operationType: 'tempPanelMode', value: 'COLD' },
                     { operationType: 'windSpeed', value: 'AUTO' },
                     { operationType: 'temp', value: 28 },
                  ]
               }
            })
         },
         // 删除产品
         delProduct(row){
            this.sProductData = this.sProductData.filter(item => {
               return item.id !== row.id
            })
            this.productData.unshift(row)
         },
         // 获取设备列表
         getDevicesList(){
            const url = urlObj.devManageList
            let param = {
               hotelId: this.hotelId,
               productId: this.productId,
               roomTypeIds: this.roomTypeIds.join(),
               roomId: this.roomId[0],
            }
            if (this.roomId.length > 1) {
               delete param.roomId
               param.roomIds = this.roomId.join()
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let devices = res.records
                  if (devices.length === 0) return this.devicesData = []
                  this.devicesData = devices.map(item => {
                     return {
                        id: item.id,
                        deviceName: item.deviceName,
                        productId: item.productId,
                        versionsName: item.versionsName,
                     }
                  })
               }
            })
         },
         // 多选当前设备
         getSelectDevices(val){
            this.selectDevices = val
         },
         // 新增选中设备
         addDevices(){
            // 判断该设备的所属产品类是否已选中，YES则阻止添加操作
            let selectProduct = []
            selectProduct = this.sProductData.filter(item => item.id === this.selectDevices[0].productId)
            if (selectProduct.length !== 0){
               this.$message({
                  showClose: true,
                  message: this.prevent_add,
                  type: 'warning'
               })
               return
            }
            // 将选中的设备添加到已选设备列表数组中
            this.selectDevices.forEach(item1 => {
               // 如果该设备已经添加到已选设备列表 则组织阻止添加操作
               let i = this.sDevicesData.findIndex(item2 => item1.id === item2.id)
               if (i !== -1) {
                  this.$message({
                     showClose: true,
                     message: this.prevent_add_dev,
                     type: 'warning'
                  })
                  return
               }
               this.sDevicesData.push(item1)
            })
            // 删除设备列表中已选中的元素
            this.packageDelSelected(this.devicesData, this.sDevicesData)
            // 判断设备类型
            this.packageJudgeType(this.sDevicesData, 'productId')
         },
         // 删除设备
         delDevices(row){
            this.sDevicesData = this.sDevicesData.filter(item => item.id !== row.id)
            this.devicesData.unshift(row)
         },
         // 封装映射字段
         packageMap(selectData, deviceScope){
            selectData.forEach(item1 => {
               // 产品类型为温控面板
               let extra = {}
               if (item1.type === 'temp'){
                  item1.params.forEach(item2 => extra[item2.operationType] = item2.value)
                  let row = {
                     productOrDeviceId: item1.id,
                     elecId: item1.elecId,
                     standard: item1.standard,
                     deviceScope,
                     indexs: 0,
                     action: item1.state,
                     extra: JSON.stringify(extra)
                  }
                  // 如果editId为空 说明是新增的产品设备
                  // 需要在新增时，删除编辑时所用的字段
                  if (!item1.editId) delete row.elecId; delete row.standard
                  this.devices.push(row)
               }
               // 产品类型为开关面板或场景面板
               else if (item1.type === 'switch'){
                  item1.params.forEach(item2 => {
                     if (item2.isPartake === 'YES'){
                        extra.state = item1.state
                        let row = {
                           productOrDeviceId: item1.id,
                           deviceScope,
                           elecId: item1.elecId,
                           indexs: Number(item2.operationType.slice(-1)),
                           action: item2.value,
                           extra: JSON.stringify(extra)
                        }
                        // 如果item.id为空 说明是新增的产品设备*参数*
                        // 需要在新增时，删除编辑时所用的字段
                        if (!item2.id) delete row.elecId
                        this.devices.push(row)
                     }
                  })
               }
               // 产品类型为智能锁、报警器等
               else {
                  this.devices.push({
                     productOrDeviceId: item1.id,
                     deviceScope,
                     indexs: 1,
                     action: item1.state,
                  })
               }
            })
         },
         // 当指定房间时 处理请求的字段
         handleRoomNum(){
            if (this.roomScope === 'ROOM') {
               this.roomId.forEach(i => this.rooms.push({roomScope: this.roomScope, roomOrTypeId: i}))
            }
         },
         // 保存创建任务
         handleSave(){
            // 表单验证
            if (!this.jobName) {
               this.$alert(this.jobName_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.jobCode) {
               this.$alert(this.jobCode_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.jobGroup) {
               this.$alert(this.jobGroup_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.conditionType === 'TIME' && !this.cronExpression) {
               this.$alert(this.cronExpression_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.handleRoomNum()
            this.packageMap(this.sProductData, 'PRODUCT')
            this.packageMap(this.sDevicesData, 'DEVICE')
            // 当生效房间为指定房间
            if (this.roomScope === 'ROOM'){
               this.rooms.forEach(item => {
                  item.buildId = this.buildIds[0]
                  item.buildUnitId = this.buildIds[1]
               })
            }
            let url = electricity.createElectricity
            let param = {
               hotelId: this.hotelId,
               job: {
                  jobName: this.jobName,
                  jobCode: this.jobCode,
                  jobGroup: this.jobGroup,
                  startDate: this.startDate || null,
                  endDate: this.endDate || null,
                  startTime: this.startTime || null,
                  endTime: this.endTime || null,
                  remark: this.remark,
                  cronExpression: this.cronExpression,
                  state: 'RUNNING',
                  jobClassName: this.conditionType
               },
               rooms: this.rooms,
               conditionType: this.conditionType,
               delayedTime: this.delayedTime * 60,
               devices: this.devices,
            }
            // 当触发条件等于房态时
            if (this.conditionType === 'ROOM_STATE') param.conditionParam = this.conditionParam
            if (this.conditionType === 'SCENE'){
               param.conditionParam = this.sceneId
               param.delayedTime = this.delayedTime * 60
            }
            if (this.conditionType === 'DOOR') param.delayedTime = this.delayedTime
            if (this.action === 'edit'){
               param.id = this.id
               param.job.id = this.jobId
               url = electricity.updateElectricity
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.create_success,
                     type: 'success'
                  })
                  this.goBack()
               }
            }).catch(err => {
               this.$message.error(err)
            })
         },
         // 返回上页
         goBack(){
            this.$router.go(-1)
         },
      },
      filters: {
         filterType(val){
            switch (val) {
               case 'switchState':
                  return val = '开关'
               case 'tempPanelMode':
                  return val = '模式'
               case 'windSpeed':
                  return val = '风速'
               case 'temp':
                  return val = '温度'
               case 'index0':
                  return val = '一位'
               case 'index1':
                  return val = '二位'
               case 'index2':
                  return val = '三位'
               case 'index3':
                  return val = '四位'
            }
         }
      }
   }
</script>
<style scoped lang="scss">
   /deep/ .switch-state .el-switch__core{
      background-color: orange!important;
   }

   .trigger-condition{
      padding-bottom: 15px;
   }
   .trigger-condition .condition-cont,
   .effective-room .room-cont{
      padding: 0 40px;
   }
   .trigger-condition .condition-cont .room-btn{
      padding: 0 0 20px 0;
   }
   .trigger-condition .condition-cont .room-btn button{
      background-color: transparent;
      padding: 16px 40px;
      border: solid 1px #cccccc;
      margin: 0 10px 10px;
      outline: none
   }
   .trigger-condition .condition-cont .room-btn .active{
      border: solid 1px #409eff;
      color: #409eff;
   }

   .effective-room .room-type .room-type-images{
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
   }
   .effective-room .room-type .room-type-images div{
      border: solid 1px #cccccc;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      margin: 0 15px 10px 0;
   }
   .effective-room .room-type .room-type-images img{
      display: block;
   }
   .effective-room .room-type .room-type-images .active{
      border: solid 1px #409eff;
      color: #409eff;
   }
   .effective-room .room{
      margin-top: 20px;
   }
   .effective-room .room-cont .room-top{
      background-color: #f6f6f6;
      padding: 0 20px;
      line-height: 40px;
      border-bottom: 1px #eeeeee solid;
   }
   .effective-room .room-cont .room-btm{
      margin: 20px 0 0 0;
   }
   .effective-room .room-cont .room-btm > div{
      margin-bottom: 26px;
   }
   .effective-room .room-cont .room-btm .room-btm-head{
      border-bottom: 1px #eeeeee solid;
      margin-bottom: 8px;
   }
   .infrared-sensor{
      font-size: 14px;
      color: #606266;
   }
   /deep/ .el-slider__marks-text{
      margin-top: 5px;
      margin-left: 2px;
      font-size: 12px;
   }

   .cont{
      min-width: 1120px;
      .box-span{
         vertical-align: middle;
         margin-left: 10px;
      }
      .row-buttom{
         text-align: center;
         padding: 20px;
         &.row-padding{
            text-align: right;
            padding-right: 110px;
         }
      }

      /deep/.el-input__inner{
         line-height: 30px;
         height: 30px;
      }
   }
   .el-row{
      line-height: 30px;
   }
   .scene-top{
      .scene-box{
         padding: 5px;
         border: 1px solid #00c1de;
         background: #e6f9fc;
         border-radius: 3px;
      }
   }
   .det-box{
      /* border: 1px solid red; */
      background: white;
      padding: 10px 20px;
      // margin-bottom: 15px;
   }
   .det-box::after{
      content: "";
      display: block;
      clear: both;
   }
   .det-box > ul{
      list-style: none;
      overflow: hidden;
   }
   .det-box > ul > li{
      float: left;
      width: 33.3%;
      height: 40px;
      margin-bottom: 20px;
      font-size: 14px;
      white-space: nowrap;
      &.active{
         width: 66.6%;
      }
   }
   .det-box > ul > li > span:first-child{
      display: inline-block;
      min-width: 140px;
      text-align: right;
      margin-bottom: 10px;
   }
   .remarks-span{
      span{
         display: inline-block;
         min-width: 140px;
         text-align: right;
         vertical-align: top;
      }
      .el-textarea{
         width: 77%;
      }
      /deep/ textarea{
         min-height: 110px!important;
      }
   }
   @media (max-width:1700px){
      .remarks-span{
         .el-textarea{
            width: 81%;
         }
      }
   }
   @media (max-width:1300px){
      .remarks-span{
         .el-textarea{
            width: 84%;
         }
      }
   }
   // @media screen and (min-width:1024px) and (max-width:1366px){

   // }
   // .ech-box{
   //   border: 1px solid red;
   //   margin-top: 15px;
   // }
   .ech-item{
      height: 300px;
      /* border: 1px solid red; */
      background: white;
      border-radius: 10px;
      position: relative;
      padding-bottom: 30px;
      padding-right: 30px;
   }
   .ech-item > .el-link{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
   }
   #status-ech{
      height: 100%;
      width: 100%;
   }
   .txt-box{
      position: absolute;
      top: 46px;
      left: 12px;
      line-height: 110px;
   }
   .hour-box{
      position: absolute;
      right: 10px;
      bottom: 50px;
   }
   .execute{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 5%;
      &>div{
         width: 48%;
      }
      .execute-room{
         display: flex;
         flex-direction: column;
      }
      .top-text{
         font-size: 20px;
         padding-left: 20px;
         height: 40px;
         line-height: 40px;
         border: 1px solid #EBEEF5;
         // border-bottom: none;
      }
      .execute-tag{
         position: relative;
         .arrow{
            position: absolute;
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 30px;
            top: 50%;
            margin-top: -15px;
            left: -6%;
            cursor: pointer;
         }
         /deep/ {
            .el-radio{
               margin-right: 10px;
            }
         }
      }
   }
</style>
